import './Home.css';

import { Link } from 'react-router-dom';

function Home(props) {

   return (
      <>
         <div className='home-card'>
            <h3 style={{ marginBottom: '0px' }}>Hello! Welcome to WrittenVerse.org!</h3>
            <p>Creating happiness one word at a time :)</p>

            <p>Publish your own creations, and share them with all!</p>
            <div className='home-card-action-buttons'>
               <Link to='/SignUp' className='btnLink'><button className='btn-signup'>Sign Up</button></Link>
               <Link to='/LogIn' className='btnLink'><button className='btn-login'>Log In</button></Link>
            </div>
         </div>
      </>
   );
}

export default Home;