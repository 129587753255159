import React, { useState } from 'react';

import './SignUp.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeftLong } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useData } from '../DataContext';

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { InputMask } from 'primereact/inputmask';
import { ColorPicker } from 'primereact/colorpicker';

import toast, { Toaster } from 'react-hot-toast';

/* Amplify Imports */
import { Auth, API } from 'aws-amplify';
import { createProfile } from '../../graphql/mutations';

function SignUp(props) {
   const navigate = useNavigate();
   const { state, dispatch } = useData();

   /* Function which updates the accessing email field stored in the data context */
   const updateDataContext = (email) => {
      dispatch({ type: 'UPDATE_ACESSING_EMAIL', payload: email });
   };

   const [signUpFormData, setSignUpFormData] = useState({
      email: '',
      password: '',
      username: '',
      phoneNumber: '',
      favoriteColor: '#00e1ff',
   });

   const [formErrors, setFormErrors] = useState({
      emailError: false,
      usernameError: false,
      passwordError: false
   })

   const isFormValid = () => {
      if (signUpFormData.email.length > 5 &&
         signUpFormData.username.length > 1 &&
         signUpFormData.password.length > 7) {

         setFormErrors({
            emailError: false,
            usernameError: false,
            passwordError: false
         });
         return true;
      }

      let emailError = false;
      let usernameError = false;
      let passwordError = false;

      if (signUpFormData.email.length < 5)
         emailError = true;

      if (signUpFormData.username.length < 1)
         usernameError = true;

      if (signUpFormData.password.length < 8)
         passwordError = true;

      setFormErrors({
         emailError: emailError,
         usernameError: usernameError,
         passwordError: passwordError
      });

      return false;
   }

   const handleSubmit = async () => {
      if (isFormValid()) {
         toast.dismiss();
         toast.loading('Creating your account...');

         try {
            await Auth.signUp({
               username: signUpFormData.email,
               password: signUpFormData.password,
               autoSignIn: {
                  enabled: true
               },
            });
         } catch (e) {

            if (e.toString().includes('UsernameExistsException')) {
               toast.dismiss();
               toast.error('That email is already associated with an account. Please reset your password');
            } else {
               toast.dismiss();
               toast.error('Something went wrong creating your account, please try again');
            }

            // always return here, if the account creation didn't work we don't want to make a profile for that bad request
            return;
         }

         try {
            await API.graphql({
               query: createProfile,
               variables: {
                  input: {
                     'username': signUpFormData.username,
                     'email': signUpFormData.email,
                     'phoneNumber': signUpFormData.phoneNumber,
                     'favoriteColor': signUpFormData.favoriteColor
                  }
               }
            });
         } catch (e) {
            toast.dismiss();
            toast.error('Something went wrong creating associated account information, oops!');

            // return here so we don't toast success since we had an error
            return;
         }

         toast.dismiss();

         updateDataContext(signUpFormData.email);
         navigate(`/VerifyEmail`);

         // we return here, if they did not have any form errors we don't want to toast the form errors
         return;
      }

      toast.error('Please fill out all required fields');
   }

   return (
      <>
         <div className='signup-card'>
            <Link to='/Home' className='btnLink'><button className='back-button'><FontAwesomeIcon icon={faLeftLong} /></button></Link>

            <h3 style={{ marginBottom: '0px' }}>Sign Up Today!</h3>
            <p>To get started, tell us about yourself...</p>


            <div className='form-group' style={{ marginTop: '10px' }}>
               <div className='input-group'>
                  <label htmlFor='email'>Email</label>
                  <InputText
                     id='email'
                     value={signUpFormData.email}
                     onChange={(e) => setSignUpFormData({ ...signUpFormData, email: e.target.value })}
                  />
                  {formErrors.emailError && (
                     <small style={{ color: 'red' }}>
                        Required
                     </small>
                  )}
               </div>

               <div className='input-group'>
                  <label htmlFor='username'>Username</label>
                  <InputText
                     id='username'
                     value={signUpFormData.username}
                     onChange={(e) => setSignUpFormData({ ...signUpFormData, username: e.target.value })}
                  />
                  {formErrors.usernameError && (
                     <small style={{ color: 'red' }}>
                        Required
                     </small>
                  )}
               </div>

               <div className='input-group'>
                  <label htmlFor='password'>Password</label>
                  <Password
                     id='password'
                     toggleMask
                     value={signUpFormData.password}
                     onChange={(e) => setSignUpFormData({ ...signUpFormData, password: e.target.value })}
                  />
                  <small>
                     Passwords must be atleast 8 characters long
                  </small>
                  {formErrors.passwordError && (
                     <small style={{ color: 'red' }}>
                        Required
                     </small>
                  )}
               </div>
               <div className='input-group-double'>
                  <div className='input-group'>
                     <label htmlFor='phoneNumber'>Phone Number</label>
                     <InputMask
                        id='phoneNumber'
                        mask="(999) 999-9999"
                        value={signUpFormData.phoneNumber}
                        onChange={(e) => setSignUpFormData({ ...signUpFormData, phoneNumber: e.target.value })}
                     />
                  </div>
                  <div className='input-group'>
                     <label htmlFor='favorite Color'>Favorite Color</label>
                     <ColorPicker
                        id='favorite Color'
                        value={signUpFormData.favoriteColor}
                        onChange={(e) => setSignUpFormData({ ...signUpFormData, favoriteColor: e.target.value })}
                     />
                  </div>
               </div>

               <div className='submitBtnRow'>
                  <button onClick={handleSubmit}>Make my Account!</button>
               </div>
            </div>

            <hr></hr>
         </div>
         <Toaster position="top-right" />
      </>
   );
}

export default SignUp;