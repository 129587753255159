import { createContext, useContext, useReducer } from 'react';

const initialState = {
   // Define your initial state here
   accessingEmail: '',
   data: [],
};

const DataContext = createContext();

export function useData() {
   return useContext(DataContext);
}

export function dataReducer(state, action) {
   switch (action.type) {
      case 'UPDATE_ACESSING_EMAIL':
         return {
            ...state,
            accessingEmail: action.payload,
         };
      // Handle other action types here
      default:
         return state;
   }
}

export function DataProvider({ children }) {
   const [state, dispatch] = useReducer(dataReducer, initialState);

   return (
      <DataContext.Provider value={{ state, dispatch }}>
         {children}
      </DataContext.Provider>
   );
}