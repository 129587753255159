import React, { useState } from 'react';
import './Login.css';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeftLong } from '@fortawesome/free-solid-svg-icons';

import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'

import toast, { Toaster } from 'react-hot-toast';

import { Auth } from 'aws-amplify';

function LogIn(props) {
   const [logInFormData, setLogInFormData] = useState({
      email: '',
      password: ''
   });

   const [formErrors, setFormErrors] = useState({
      emailError: false,
      passwordError: false,
   });

   /**
    * Only invalid form state for logging in is if either input box does not have a value (email or password)
    * @returns 
    */
   const isFormValid = () => {
      if (logInFormData.email.length > 0 &&
         logInFormData.password.length > 0) {

         setFormErrors({
            emailError: false,
            passwordError: false
         });
         return true;
      }

      let emailError = false;
      let passwordError = false;

      if (logInFormData.email.length < 1)
         emailError = true;

      if (logInFormData.password.length < 1)
         passwordError = true;

      setFormErrors({
         emailError: emailError,
         passwordError: passwordError
      });

      return false;
   }

   const handleLogIn = async () => {
      toast.dismiss();

      if (!isFormValid()) {
         toast.error('Please fill out all fields to log in');
         return;
      }

      toast.loading('Logging in...');

      try {
         await Auth.signIn(logInFormData.email, logInFormData.password);
         toast.dismiss();
         toast.success('Login Successful!');
      } catch (e) {
         console.log('e: ', e)
         toast.dismiss();
         toast.error('Login failed! Check your credentials, and try again');
      }
   }

   return (
      <>
         <div className='login-card'>
            <Link to='/Home' className='btnLink'><button className='back-button'><FontAwesomeIcon icon={faLeftLong} /></button></Link>
            <h3>Log In</h3>
            <p>Welcome back!</p>

            <div className='form-group' style={{ marginTop: '10px' }}>
               <div className='input-group'>
                  <label htmlFor='email'>Email</label>
                  <InputText
                     id='email'
                     value={logInFormData.email}
                     onChange={(e) => setLogInFormData({ ...logInFormData, email: e.target.value })}
                  />
                  {formErrors.emailError && (
                     <small style={{ color: 'red' }}>
                        Please enter an email
                     </small>
                  )}
               </div>
               <div className='input-group'>
                  <label htmlFor='password'>Password</label>
                  <Password
                     id='password'
                     toggleMask
                     value={logInFormData.password}
                     onChange={(e) => setLogInFormData({ ...logInFormData, password: e.target.value })}
                  />
                  {formErrors.passwordError && (
                     <small style={{ color: 'red' }}>
                        Please enter a password
                     </small>
                  )}
               </div>
            </div>
            <div>
               <button className='login-btn' onClick={handleLogIn}>Log In!</button>
            </div>
            <hr></hr>
         </div>
         <Toaster position="top-right" />
      </>
   );
}

export default LogIn;