import React from 'react';

import './AccountCreated.css';

import { Link } from 'react-router-dom';

function AccountCreated(props) {

   return (
      <>
         <div className='accountCreated-card'>
            <h3>Account Creation Complete!</h3>
            <p>Now you can complete your profile, and start publishing your work to the site!</p>
            <p>Thank you for choosing to become apart of our community!</p>
            
            <div>
               <Link to='/Home' className='btnLink'><button className='backToMainSite-btn'>Lets get started!</button></Link>
            </div>
            <hr></hr>
         </div>
      </>
   );
}

export default AccountCreated;