import React, { useEffect } from 'react';
import './Navbar.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faRightFromBracket, faCalendar, faSquarePhone } from '@fortawesome/free-solid-svg-icons';

function Navbar(props) {

   useEffect(() => {
      const typingAnimation = document.querySelector('.title-typing-text')

      // at the end of the first animation (starting typing) we add the animate class to trigger the border bottom growing animation
      typingAnimation.addEventListener('animationend', () => {
         typingAnimation.classList.add('animate')
      })

      return () => { }
   }, [])

   return (
      <nav className='navbar'>

         <div className='titleContent'>
            <span className='title title-typing-text title-border-grow'>Written Verse</span>
         </div>

      </nav>
   );
}

export default Navbar;