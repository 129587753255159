import './App.css';

import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { createBrowserHistory } from 'history';

import RoutingTransitionWrapper from './components/RoutingTransitionWrapper';
import Navbar from './components/Navbar/Navbar';

import { Amplify } from 'aws-amplify'
import awsconfig from './aws-exports.js'

// this history allows the browser forward-back arrows to not trigger "page blink"
const history = createBrowserHistory();

Amplify.configure(awsconfig);

function App() {
   return (
      <BrowserRouter history={history}>
         <>
            <Navbar />
            <AnimatePresence mode="wait">
               <RoutingTransitionWrapper />
            </AnimatePresence>
            <span className='footer'>© [2023] WrittenVerse.org. All Rights Reserved.</span>
         </>
      </BrowserRouter>
   );
}

export default App;
