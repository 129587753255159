import { Routes, Route, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

/* Components */
import Home from './Home/Home';
import SignUp from './SignUp/SignUp';
import LogIn from './LogIn/LogIn';
import AccountCreated from './AccountCreated/AccountCreated';
import VerifyEmail from './VerifyEmail/VerifyEmail';

/* wrapper for route map, sets transition animations on route change */
function RoutingTransitionWrapper(props) {
   const location = useLocation();

   return (
      <motion.div
         key={location.pathname}
         initial={{ opacity: 0, y: 0, scale: 0.99 }}
         animate={{ opacity: 1, y: 0, scale: 1 }}
         exit={{ opacity: 0, y: 0, scale: 0.99 }}
         transition={{ duration: 0.3 }}
         style={{ height: '100%' }}
      >
         <Routes location={location}>
            <Route path='/Home' element={<Home />} />
            <Route path='/*' element={<Home />} />

            <Route path='/SignUp' element={<SignUp />} />
            <Route path='/VerifyEmail' element={<VerifyEmail />} />
            <Route path='/AccountCreated' element={<AccountCreated />} />
            <Route path='/LogIn' element={<LogIn />} />

         </Routes>
      </motion.div>
   );
}

export default RoutingTransitionWrapper;