import './VerifyEmail.css';
import React, { useEffect, useState } from 'react';

import { useData } from '../DataContext';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeftLong } from '@fortawesome/free-solid-svg-icons';

import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Auth } from 'aws-amplify';
import toast, { Toaster } from 'react-hot-toast';

function VerifyEmail(props) {
   const { state, dispatch } = useData();
   const navigate = useNavigate();

   const [code, setCode] = useState();
   const [email, setEmail] = useState(state.accessingEmail);

   const [formErrors, setFormErrors] = useState({
      emailError: false,
      codeError: false
   })

   const isFormValid = () => {
      if (code && code.length > 0 &&
         email && email.length > 0) {

         setFormErrors({
            emailError: false,
            codeError: false
         });

         return true;
      }

      let emailError = false;
      let codeError = false;

      if (email && email.length < 1)
         emailError = true;

      if (code && code.length < 1)
         codeError = true;

      setFormErrors({
         emailError: emailError,
         codeError: codeError
      });

      return false;
   }

   const confirmSignUp = async () => {
      toast.loading('Confirming Email...');

      if (!isFormValid()) {
         toast.dismiss();
         toast.error('Please fill out all required fields');
         return;
      }

      try {
         await Auth.confirmSignUp(email, code.trim());
         toast.dismiss();
         navigate(`/AccountCreated`);
      } catch (e) {
         toast.dismiss();
         toast.error('Something went wrong verifying your email, please ensure the code is correct')
      }
   }

   return (
      <>
         <div className='verifyEmail-card'>
            <Link to='/Home' className='btnLink'><button className='back-button'><FontAwesomeIcon icon={faLeftLong} /></button></Link>
            <h3>Almost There!</h3>
            <p>A verififcation email was sent to your email used to sign up.</p>
            <p>Enter the code sent below!</p>
            <div className='form-group' style={{ marginTop: '10px' }}>
               <div className='input-group'>
                  <label htmlFor='email'>Email</label>
                  <InputText
                     id='email'
                     value={email}
                     onChange={(e) => setEmail(e.target.value)}
                  />
                  {formErrors.emailError && (
                     <small style={{ color: 'red' }}>
                        Required
                     </small>
                  )}
               </div>
               <div className='input-group'>
                  <label htmlFor='code'>Verification Code</label>
                  <InputText
                     id='code'
                     value={code}
                     onChange={(e) => setCode(e.target.value)}
                  />
                  {formErrors.codeError && (
                     <small style={{ color: 'red' }}>
                        Required
                     </small>
                  )}
               </div>
            </div>

            <div>
               <button onClick={confirmSignUp} className='backToMainSite-btn'>Verify My Email</button>
            </div>
            <hr></hr>
         </div>
         <Toaster position="top-right" />
      </>
   );
}

export default VerifyEmail;